/* took forever to figure this import out */
@import "npm:normalize.css/normalize.css";

@font-face {
	font-display: swap;
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 700;
	src: url("../fonts/work-sans-v18-latin-700.woff2") format("woff2");
}

@font-face {
	font-display: swap;
	font-family: "Merriweather";
	font-style: normal;
	font-weight: 400;
	src: url("../fonts/merriweather-v30-latin-regular.woff2") format("woff2");
}

[x-cloak] {
	display: none !important;
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

html,
body {
	position: relative;
	min-height: 100vh;
}

body {
	padding-bottom: 64px;
	font-family: "Merriweather", serif;
	font-weight: 400;
	font-size: 16px;
	background-color: #111;
}

.header {
	position: sticky;
	top: 0;
	display: flex;
	place-content: center;
	place-items: center;
	height: 64px;
	padding-left: 36px;
	padding-right: 36px;
	backdrop-filter: blur(10px) grayscale(100%);
	z-index: 1000;

	@media (width >= 600px) {
		place-content: flex-start;
	}

	&__logo {
		display: block;
		width: 140px;

		&__svg {
			width: 100%;
			height: auto;

			&__reel {
				fill: #cc7750;
				transform-box: fill-box;
				transform-origin: center;
				animation: periodicSpin 8s linear infinite;

				@keyframes periodicSpin {
					0% {
						transform: rotatez(0);
					}

					90% {
						transform: rotatez(0);
					}

					100% {
						transform: rotatez(360deg);
					}
				}
			}
		}
	}
}

.app {
	display: flex;
	padding-top: 36px;
	padding-bottom: 36px;
	place-content: center;
}

.loading-screen {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	place-items: center;
	place-content: center;
	gap: 24px;
	width: 100%;
	height: 100%;
	background-color: #111;
	z-index: 500;

	&__icon {
		max-width: 30%;

		&__reel {
			transform-box: fill-box;
			transform-origin: center;
			animation: continuousSpin 2s linear infinite;

			@keyframes continuousSpin {
				0% {
					transform: rotatez(0);
				}

				100% {
					transform: rotatez(360deg);
				}
			}
		}
	}
}

.movie {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 1200px;
	padding-left: 36px;
	padding-right: 36px;

	&-poster {
		align-self: flex-end;
		width: 80%;
		height: auto;
		aspect-ratio: 1;
		border-top-left-radius: 24px;
		border-bottom-right-radius: 24px;
		overflow: hidden;

		&__img {
			display: block;
			width: 100%; // should always be square
			height: auto;
			aspect-ratio: 1;
		}
	}

	&-details {
		align-self: flex-start;
		width: 80%;
		margin-top: -50px;
		padding: 54px 36px 36px;
		color: white;
		backdrop-filter: blur(10px) grayscale(100%);
		border-top: 5px solid #ffffff40;
		border-bottom: 1px solid #ffffff40;

		@media (width >= 1024) {
			display: flex;
			flex-wrap: wrap;
		}

		&-group {
			@media (width >= 1024) {
				flex: 1 0 33%;

				&:first-of-type,
				&:nth-of-type(2) {
					flex-basis: 100%;
				}
			}
		}

		&__label {
			font-family: "Work Sans";
			font-weight: 700;
			font-size: 12px;
			color: #cc7750;
			text-transform: uppercase;
			letter-spacing: 0.075rem;

			@media (width >= 600px) {
				font-size: 14px;
			}
		}

		&__value {
			margin-top: 8px;
			margin-bottom: 20px;
			font-size: 16px;

			@media (width >= 600px) {
				font-size: 20px;
			}

			&--title {
				font-size: 22px;

				@media (width >= 600px) {
					font-size: 28px;
				}
			}
		}
	}
}

.meta {
	position: fixed;
	display: flex;
	place-content: center;
	gap: 16px;
	right: 16px;
	bottom: 0;
	z-index: 1000;

	&__link {
		display: flex;
		place-content: center;
		place-items: center;
		width: 48px;
		height: 48px;
		border: 0;
		background-color: #cc7750;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		cursor: pointer;

		& > svg {
			display: block;
			max-width: 32px;
			max-height: 32px;
		}
	}
}
