html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

@font-face {
  font-display: swap;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 700;
  src: url("work-sans-v18-latin-700.ebc03247.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Merriweather;
  font-style: normal;
  font-weight: 400;
  src: url("merriweather-v30-latin-regular.395cc7e5.woff2") format("woff2");
}

[x-cloak] {
  display: none !important;
}

*, :before, :after {
  box-sizing: border-box;
}

html, body {
  min-height: 100vh;
  position: relative;
}

body {
  background-color: #111;
  padding-bottom: 64px;
  font-family: Merriweather, serif;
  font-size: 16px;
  font-weight: 400;
}

.header {
  height: 64px;
  -webkit-backdrop-filter: blur(10px) grayscale();
  backdrop-filter: blur(10px) grayscale();
  z-index: 1000;
  place-content: center;
  place-items: center;
  padding-left: 36px;
  padding-right: 36px;
  display: flex;
  position: sticky;
  top: 0;
}

@media (width >= 600px) {
  .header {
    place-content: flex-start;
  }
}

.header__logo {
  width: 140px;
  display: block;
}

.header__logo__svg {
  width: 100%;
  height: auto;
}

.header__logo__svg__reel {
  fill: #cc7750;
  transform-box: fill-box;
  transform-origin: center;
  animation: 8s linear infinite periodicSpin;
}

@keyframes periodicSpin {
  0% {
    transform: rotateZ(0);
  }

  90% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

.app {
  place-content: center;
  padding-top: 36px;
  padding-bottom: 36px;
  display: flex;
}

.loading-screen {
  width: 100%;
  height: 100%;
  z-index: 500;
  background-color: #111;
  place-content: center;
  place-items: center;
  gap: 24px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.loading-screen__icon {
  max-width: 30%;
}

.loading-screen__icon__reel {
  transform-box: fill-box;
  transform-origin: center;
  animation: 2s linear infinite continuousSpin;
}

@keyframes continuousSpin {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

.movie {
  width: 100%;
  max-width: 1200px;
  flex-direction: column;
  padding-left: 36px;
  padding-right: 36px;
  display: flex;
  position: relative;
}

.movie-poster {
  width: 80%;
  height: auto;
  aspect-ratio: 1;
  border-top-left-radius: 24px;
  border-bottom-right-radius: 24px;
  align-self: flex-end;
  overflow: hidden;
}

.movie-poster__img {
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  display: block;
}

.movie-details {
  width: 80%;
  color: #fff;
  -webkit-backdrop-filter: blur(10px) grayscale();
  backdrop-filter: blur(10px) grayscale();
  border-top: 5px solid #ffffff40;
  border-bottom: 1px solid #ffffff40;
  align-self: flex-start;
  margin-top: -50px;
  padding: 54px 36px 36px;
}

@media (width >= 1024px) {
  .movie-details {
    flex-wrap: wrap;
    display: flex;
  }

  .movie-details-group {
    flex: 1 0 33%;
  }

  .movie-details-group:first-of-type, .movie-details-group:nth-of-type(2) {
    flex-basis: 100%;
  }
}

.movie-details__label {
  color: #cc7750;
  text-transform: uppercase;
  letter-spacing: .075rem;
  font-family: Work Sans;
  font-size: 12px;
  font-weight: 700;
}

@media (width >= 600px) {
  .movie-details__label {
    font-size: 14px;
  }
}

.movie-details__value {
  margin-top: 8px;
  margin-bottom: 20px;
  font-size: 16px;
}

@media (width >= 600px) {
  .movie-details__value {
    font-size: 20px;
  }
}

.movie-details__value--title {
  font-size: 22px;
}

@media (width >= 600px) {
  .movie-details__value--title {
    font-size: 28px;
  }
}

.meta {
  z-index: 1000;
  place-content: center;
  gap: 16px;
  display: flex;
  position: fixed;
  bottom: 0;
  right: 16px;
}

.meta__link {
  width: 48px;
  height: 48px;
  cursor: pointer;
  background-color: #cc7750;
  border: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  place-content: center;
  place-items: center;
  display: flex;
}

.meta__link > svg {
  max-width: 32px;
  max-height: 32px;
  display: block;
}

/*# sourceMappingURL=index.4fe4f9c0.css.map */
